<template>
    <div class="upload-layout no-auto">
        <el-upload class="avatar-uploader" :action="action" :show-file-list="false"
            :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
            <img v-if="!hideImg && url" :src="url" class="avatar" loading="lazy">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <p v-if="desc" class="desc">{{ desc }}</p>
    </div>
</template>

<script>
import config from '../../common/request/config';
export default {
    name: 'Upload',
    props: {
        value: {
            type: String,
            default: ''
        },
        hideImg: {
            type: Boolean,
            default: false
        },
        desc: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            action: config.upload
        };
    },
    computed: {
        url: {
            get(){
                if (this.$props.value){
                    return window.global_config.BASE_IMG_URL + this.$props.value
                } else {
                    return undefined
                }
            },
            set(v){
                this.$emit('input', v)
            }
        }
    },
    methods: {
        handleAvatarSuccess(res, file) {
            this.imageUrl = URL.createObjectURL(file.raw);
            if(res.code === 1){
                console.log(res.data);
                // this.url = window.global_config.BASE_URL + res.data.url
                this.url = res.data.url
            }
        },
        beforeAvatarUpload(file) {
            // const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;

            // if (!isJPG) {
            //     this.$message.error('上传头像图片只能是 JPG 格式!');
            // }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return !!isLt2M;
        }
    }
}
</script>

<style lang="less" scoped>
    @w: 106px;
    .upload-layout{
        display: flex;
        align-items: flex-end;
        :deep(.avatar-uploader-icon){
            width: @w;
            height: @w;
            line-height: @w;
        }
    }
    .avatar-uploader{
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f2f3f5;
        :deep(.el-upload){
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            &:hover{
                border-color: #409EFF;
            }
        }
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: @w;
        height: @w;
        line-height: @w;
        text-align: center;
    }

    .avatar {
        width: @w;
        height: @w;
        display: block;
    }

    .desc{
        font-size: 14px;
        margin-top: 8px;
        color: #999;
        margin-left: 14px;
        white-space: nowrap;
    }
</style>
<template>
    <el-select v-model="modelValue" placeholder="请选择" clearable>
        <el-option
        v-for="item in selects"
        :key="item.value"
        :label="item.label"
        :value="item.value"
        >
        </el-option>
    </el-select>
</template>
  
<script>
export default {
    inheritAttrs: false,
    name: 'Select',
    props: ['value', 'options', 'api'],
    data() {
        return {
            selects: []
        }
    },
    created(){
        if(this.$props.options){
            this.selects = this.$props.options
        } else if(this.$props.api){
            this.$props.api()
            .then(res => {
                this.selects = res.data.map(item => ({label: item.name, value: item.id}))
            })
        } else {
           console.error('Select组件 缺少参数 { options } or { api }')
        }
    },
    computed: {
        modelValue: {
            get() {
                return this.$props.value
            },
            set(v){
                this.$emit('input', v)
            }
        }
    }
}
</script>
<template>
    <div class="ui-switch">
        <el-switch v-bind="$attrs" v-on="$listeners"></el-switch>
    </div>
</template>

<script>
    export default {
        name: 'u-Switch',
        data(){
            return {

            }
        },
    }
</script>

<style lang="less" scoped>
    .ui-switch{
        display: flex;
        align-items: center;
        height: 32px;
    }
</style>

<template>
    <div class="comm-table" ref="table">
        <el-table ref="tableinp"
        :data="tableData"
        size="medium"
        stripe
        sortable
        :page-size="pagesize"
        :header-cell-style="{color: '#333'}"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        v-bind="$attrs"
        v-on="$listeners">
            <el-table-column v-if="checked" type="selection" width="50" align="center">
            </el-table-column>

            <el-table-column v-for="item in tableFields" :key="item.id" align="center" :prop="item.prop" :label="item.label" :width="item?item.width : 'auto'" v-bind="item.componentProps">
                <template v-slot="{ row }">
                    <slot :name="item.prop" :row="row">
                        {{ handleText(row[item.prop]) }}
                    </slot>
                </template>
            </el-table-column>
        </el-table>
        <div class="page-wrapper" v-if="total > 0">
            <el-pagination background layout="prev, pager, next" :total="total" @current-change="handleCurrentChange" style="margin-top: 15px; flex-shrink: 0;">
            </el-pagination>
        </div>
    </div>
</template>

<script>

// function getElementTop(element){
//     var actuatop=element.offsetTop;
//     var current=element.offsetParent;
//     while(current !==null) {
//         actuatop += current.offsetTop;
//         current=current.offsetParent;
//     }
//     return actuatop;
// }

export default {
    components: {

    },
    name: 'Table',
    props: {
        tableData: {
            type: Array,
            default: []
        },
        tableFields: {
            type: Array,
            default: () => []
        },
        total: {
            type: Number,
            default: 0
        },
        pagesize: {
            type: Number,
            default: 10
        },
        checked: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            height: 200,
        }
    },
    mounted() {
        // console.log(this.$attrs);
        // console.log(this.$props);
        // console.log(this.$listeners);
        // console.log(this.$refs);
        this.onResize()
    },
    methods: {
        handleText(v){
            if(v === null || v === undefined) return '--'
            return v
        },
        handleCurrentChange(v) {
            this.$emit('handleCurrentChange', v)
        },
        onResize() {
            const inp = this.$refs.tableinp;
            let instanceMethods = Object.keys(inp).filter((key) => typeof inp[key] === 'function').map((methodName) => ({ [methodName]: inp[methodName].bind(null) }));
            instanceMethods.forEach((item) => {
                Object.entries(item).forEach(([key, value]) => {
                    this[key] = value;
                })
            })
            const dom = this.$refs.table
            this.$nextTick(() => {
                setTimeout(()=>{
                    const rect = dom.getBoundingClientRect();
                    // this.height = window.innerHeight - rect.top - 22;
                }, 100)
            })
        }
    },
}
</script>


<style lang="less" scoped>
.comm-table {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #fff;
}
.page-wrapper {
    padding-bottom: 15px;
}
</style>